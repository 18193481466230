body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

header {
	background: #1e65ff;
  padding: 20px;
  display: flex;
  align-items: center;
	/* display: grid; */
}

header h1 {
	color: white;
	max-width: 50%;
	margin-right: auto;
}

h2 {
	padding-bottom: 10px;
	border-bottom: 1px solid #eee;
}

.content {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	padding: 20px;
	display: grid;
	grid-template-columns: 2fr 1fr;
	gap: 100px;
}

.theme-button-dark {
	background-color: #777;
	border: none;
	color: white;
	/* padding: 15px 32px; */
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	/* margin: 4px 2px; */
	margin-left: auto;
	cursor: pointer;
	height: 40px;
	width: 120px;
	border-radius: 5px;
}

.theme-button-light {
	background-color: #eee;
	border: none;
	color: #777;
	/* padding: 15px 32px; */
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	/* margin: 4px 2px; */
	margin-left: auto;
	cursor: pointer;
	height: 40px;
	width: 120px;
	border-radius: 5px;
}
