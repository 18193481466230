.skeleton {
	background: #ddd;
	margin: 10px 0;
	border-radius: 4px;
}

.skeleton.text {
	width: 100%;
	height: 12px;
}

.skeleton.title {
	width: 50%;
	height: 20px;
	margin-bottom: 15px;
}

.skeleton.avatar {
	width: 100px;
	height: 100px;
	border-radius: 50%;
}

.skeleton.thumbnail {
	width: 100px;
	height: 100px;
}

.skeleton-wrapper {
	margin: 20px auto;
	padding: 10px 15px;
	border-radius: 4px;
	position: relative;
	overflow: hidden;
}

/* Skeleton Profile */
.skeleton-profile {
	display: grid;
	grid-template-columns: 1fr 2fr;
	gap: 30px;
	align-items: center;
}

/* Themes */

.skeleton-wrapper.light {
	background: #f2f2f2;
}

.skeleton-wrapper.dark {
	background: #444;
}

.skeleton-wrapper.dark .skeleton {
	background: #777;
}

/* Animation effects */

.shimmer-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	animation: loading 2.5s infinite;
}

.shimmer {
	width: 50%;
	height: 100%;
	background: rgb(255, 255, 255, 0.2);
	transform: skewX(-20deg);
	box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05);
}

.dark .shimmer {
	background: rgb(255, 255, 255, 0.05);
}

@keyframes loading {
	0% {
		transform: translateX(-150%);
	}
	50% {
		transform: translateX(-60%);
	}
	100% {
		transform: translateX(150%);
	}
}
